<script>
export default {
  name: 'Error404Module',
};
</script>

<template>
<div class="error">
Error404
</div>
</template>

<style scoped lang="scss">
</style>
