<script>
export default {
  name: 'ContentModule',
};
</script>

<template>
<div class="content-wrapper">
  <router-view></router-view>
</div>
</template>

<style scoped lang="scss">
.content-wrapper {
  width: 100%;
  background: var(- -main-light);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
