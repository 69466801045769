import { createRouter, createWebHistory } from 'vue-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Error404Module from '@/content/Error404Module.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/handler',
      name: 'handler',
      component: () => import('@/content/handler.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '',
      name: 'sections',
      component: () => import('@/content/sections/sectionwrapper.vue'),
      meta: {
        title: 'SeptaCode',
        auth: false,
        metaTags: [
          {
            name: 'description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
          {
            property: 'og:description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
        ],
      },
    },
    {
      path: '/villkor',
      name: 'terms',
      component: () => import('@/content/terms.vue'),
      meta: {
        title: 'SeptaCode - Villkor',
        auth: false,
        metaTags: [
          {
            name: 'description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
          {
            property: 'og:description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
        ],
      },
    },
    {
      path: '/minasidor',
      name: 'mypages',
      component: () => import('@/content/mypages.vue'),
      redirect: { name: 'websites' },
      meta: {
        title: 'SeptaCode - Mina sidor',
        auth: true,
        metaTags: [
          {
            name: 'description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
          {
            property: 'og:description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
        ],
      },
      children: [
        {
          path: 'loggain',
          alias: 'loggain*',
          name: 'login',
          component: () => import('@/content/mypages/login.vue'),
          meta: {
            auth: false,
          },
        },
        {
          path: 'losenordsaterstallning',
          name: 'resetpassword',
          component: () => import('@/content/resetpassword.vue'),
          meta: {
            auth: false,
          },
        },
        {
          path: 'trafikanalys',
          name: 'analytics',
          component: () => import('@/content/mypages/analytics.vue'),
          props: true,
          meta: {
            auth: true,
          },
        },
        {
          path: 'webbplatser',
          name: 'websites',
          component: () => import('@/content/mypages/websites.vue'),
          props: true,
          meta: {
            auth: true,
          },
        },
        {
          path: 'prenumerationer',
          name: 'subscriptions',
          component: () => import('@/content/mypages/subscriptions.vue'),
          props: true,
          meta: {
            auth: true,
          },
        },
        {
          path: 'fakturor',
          name: 'invoices',
          component: () => import('@/content/mypages/invoices.vue'),
          props: true,
          meta: {
            auth: true,
          },
        },
        {
          path: 'installningar',
          name: 'settings',
          component: () => import('@/content/mypages/settings.vue'),
          props: true,
          meta: {
            auth: true,
          },
        },
        {
          path: 'loggaut',
          name: 'signout',
          beforeEnter: () => {
            getAuth().signOut();
            router.push({ name: 'login' });
          },
          meta: {
            auth: false,
          },
        },
        {
          path: 'admin',
          name: 'admin',
          redirect: { name: 'domains' },
          meta: {
            auth: true,
            admin: true,
          },
        },
        {
          path: 'admin/domains',
          name: 'domains',
          component: () => import('@/content/mypages/admin/domains.vue'),
          meta: {
            auth: true,
            admin: true,
          },
        },
        {
          path: 'admin/subscribers',
          name: 'subscribers',
          component: () => import('@/content/mypages/admin/subscribers.vue'),
          meta: {
            auth: true,
            admin: true,
          },
        },
        {
          path: 'admin/editclient/:customerId',
          name: 'editClient',
          props: true,
          component: () => import('@/content/mypages/admin/editClient.vue'),
          meta: {
            auth: true,
            admin: true,
          },
        },
      ],
    },
    {
      path: '/om',
      name: 'about',
      component: () => import('@/content/about.vue'),
      meta: {
        title: 'SeptaCode - Om',
        auth: false,
        metaTags: [
          {
            name: 'description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
          {
            property: 'og:description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
        ],
      },
    },
    {
      path: '/kontakt',
      name: 'contact',
      component: () => import('@/content/contact.vue'),
      meta: {
        title: 'SeptaCode - Kontakt',
        auth: false,
        metaTags: [
          {
            name: 'description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
          {
            property: 'og:description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
        ],
      },
    },
    {
      path: '/bestallning',
      name: 'order',
      component: () => import('@/content/order.vue'),
      props: true,
      meta: {
        title: 'SeptaCode - Beställ',
        auth: false,
        metaTags: [
          {
            name: 'description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
          {
            property: 'og:description',
            content: 'Digitalisera ditt varumärke med professionell onlinenärvaro',
          },
        ],
      },
    },
    {
      path: '/:lang([a-z][a-z])?/:catchAll(.*)?',
      name: 'error404',
      component: Error404Module,
      meta: {
        title: 'Page not found',
        auth: false,
        metaTags: [
          {
            name: 'robots',
            content: 'noindex',
          },
          {
            property: 'og:description',
            content: 'Page not found',
          },
        ],
      },
    },
  ],
});

router.beforeEach((to, from, next) => new Promise(onAuthStateChanged(getAuth(), (user) => {
  let promise = false;
  if (to.name === 'login') {
    return next();
  }
  if (to.meta.auth || to.meta.admin) {
    if (!user || user.isAnonymous) {
      getAuth().signOut();
      return next({ name: 'login' });
    }
    if (to.meta.admin) {
      promise = user.getIdTokenResult().then((idTokenResult) => {
        if (!idTokenResult.claims || !idTokenResult.claims.admin) {
          return next({ name: 'websites' });
        }
        return false;
      });
    }
  }
  return Promise.resolve(promise).then((result) => {
    if (result) return result;
    // This goes through the matched routes from last to first, finding the
    // closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have
    // titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
    // If a route with a title was found, set the document (page) title to that
    // value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
    // Remove any stale meta tags from the document using the key attribute we
    // set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      // We use this to track which meta tags we create, so we don't interfere
      // with other ones.
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag));
    return next();
  });
})));

export default router;
