<script>
/* eslint-disable max-len */
export default {
  name: 'HeaderModule',
  mounted() {
    if (this.$route.hash) {
      this.$nextTick(() => {
        this.scrollFix(this.$route.hash, 1);
      });
    }
  },
  methods: {
    scrollFix: (hashbang) => {
      window.location.href = hashbang;
    },
  },
};
</script>

<template>
  <div class="header-wrapper py-3 px-3 shadow-2 flex align-items-center justify-content-between relative xl:static">
    <router-link class="logo" to="/" exact></router-link>
    <div id="mobileMenuToggle" class="cursor-pointer block xl:hidden text-700 pi pi-arrow text-4xl"></div>
    <div class="align-items-center flex-grow-1 justify-content-end xl:flex hidden w-full">
      <ul class="list-none p-0 m-0 flex align-items-center text-900 select-none flex-row cursor-pointer">
        <li class="relative"><a
          class="flex px-0 xl:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
            <span><router-link class="menuBtn" to="/#tjanster"
                @click.native="scrollFix('/#tjanster')">Tjänster</router-link></span>
        </a></li>
        <li><a
          class="flex px-0 xl:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
            <span><router-link class="menuBtn" to="/#projekt"
                @click.native="scrollFix('/#projekt')">Projekt</router-link></span>
        </a></li>
        <li><a
          class="flex px-0 xl:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
            <span><router-link class="menuBtn" to="/#prisplaner"
                @click.native="scrollFix('/#prisplaner')">Prisplaner</router-link></span>
        </a></li>
        <li><a
          class="flex px-0 xl:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
            <span><router-link class="menuBtn" :to="{ name: 'mypages' }">Mina sidor</router-link></span>
        </a></li>
        <li><a
          class="flex px-0 xl:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
            <span><router-link class="menuBtn" :to="{ name: 'about' }">Om</router-link></span>
        </a></li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mobileDrop {
  background: var(--color-bg-light);
  transition: all 0.3s ease;
  overflow: none;
}
.logo {
  justify-self: start;
  background: url("../resources/logo-white.svg") no-repeat center;
  background-size: contain;
  width: 20rem;
  height: 3rem;
  margin-right: 2rem;
}
.menuBtn {
  height: 1rem;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  color:var(--color-logo-light);
  font-weight: normal;
  text-decoration: none;
  font-size: 1.5rem;
}
.menuBtn:hover {
  color:var(--color-text-primary)
};
.header-wrapper {
  color: var(--color-logo-light);
  background: var(--color-bg-dark);
  background: linear-gradient(90deg,
  var(--color-bg-dark) 0%,
  var(--color-bg-light) 75%),
  var(--color-bg-dark) 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.2rem solid;
  border-color: var(--color-bg-light);
  .pi-bars {
    color: var(--color-logo-light);
  }
}
</style>
