<script>
import Social from '@/components/social.vue';
import Menu from '@/components/footer-menu.vue';

export default {
  name: 'FooterModule',
  components: {
    Menu,
    Social,
  },
};
</script>

<template>
<div class="footer-wrapper">
  <div class="footer-floater">
    <Menu title="Om">
      <router-link class="footerBtn" :to="{ name: 'about' }">Vad är SeptaCode?</router-link>
      <router-link class="footerBtn" :to="{ name: 'contact' }">Kontakt</router-link>
      <router-link class="footerBtn" :to="{ name: 'terms' }">Villkor</router-link>
    </Menu>
    <Social />
  </div>
</div>
</template>

<style scoped lang="scss">
@import "node_modules/include-media/dist/include-media";

.footerBtn, .footerBtn:visited {
  color: var(--color-text-secondary);
  text-decoration: none;
}

.footerBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--color-bg-dark);
  color: var(--color-text-alt);
  padding: 1.5em 0;
  @include media("<=33rem"){
    text-align: center;
  }
}

.footer-floater {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 600px;
  margin: 0 20px;
  justify-content: center;
}
</style>
