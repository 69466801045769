<script>
export default {
  name: 'FooterMenuModule',
  props: {
    title: String,
  },
};
</script>

<template>
<div class="footer-menu">
  <p class="title">{{ title }}</p>
  <slot></slot>
</div>
</template>

<style scoped lang="scss">
@import "node_modules/include-media/dist/include-media";

.footer-menu {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  margin-right: 1em;
  font-family: GilroyBold, sans-serif;
}

.title {
  color: var(--color-text-primary);
  padding: 0;
  margin: 0;
  font-size: 1.2em;
  margin-bottom: 0.3em;
}

.footerBtn {
  color: var(--color-text-primary);
  text-decoration: none;
  margin-top: 0.3em;
}

.footerBtn:hover {
  color: var(--color-text-alt);
}
</style>
