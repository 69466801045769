<script>

export default {
  name: 'MenuDropModule',
  created() {
    window.addEventListener('resize', this.closeMenuOnResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.closeMenuOnResize);
  },
  mounted() {
    const menuToggle = document.getElementById('mobileMenuToggle');
    if (menuToggle) {
      menuToggle.addEventListener('click', this.clickHandler);
    }
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu(state) {
      const menu = document.getElementById('mobileMenuToggle');
      const dropdown = document.getElementById('mobileMenuDropdown');
      menu.classList.toggle('open', state);
      dropdown.classList.toggle('close', !state);
    },
    closeMenuOnResize(e) {
      if (e.target.outerWidth > 530) {
        this.toggleMenu(false);
      }
    },
    scrollFix(hashbang) {
      window.location.href = hashbang;
      this.toggleMenu(false);
    },
    clickHandler(e) {
      if (e.target.id !== 'mobileMenuToggle') {
        this.toggleMenu(false);
      } else {
        this.menuOpen = !this.menuOpen;
        this.toggleMenu(this.menuOpen);
      }
    },
  },
};
</script>

<template>
<div id="mobileMenuDropdown" class="mobile-dropdown close"
  v-click-outside="clickHandler">
  <router-link class="menu-item" to="/" @click="toggleMenu(false)" exact>Hem</router-link>
  <router-link class="menu-item" to="/#services"
    @click.native="scrollFix('/#services')">Tjänster</router-link>
  <router-link class="menu-item" to="/#projects"
    @click.native="scrollFix('/#projects')">Projekt</router-link>
  <router-link class="menu-item" to="/#pricing"
    @click.native="scrollFix('/#pricing')">Prisplaner</router-link>
  <router-link class="menu-item" to="/mypages" @click="toggleMenu(false)">Mina sidor</router-link>
  <router-link class="menu-item" to="/about" @click="toggleMenu(false)">Om</router-link>
</div>
</template>

<style scoped lang="scss">
.mobile-dropdown {
  background: var(--color-bg-light);
  transition: all 0.3s ease;
  width: 100%;
  height: auto;
  max-height: 100vh;
  border-bottom: 0.1em solid;
  border-color: var(--color-bg-dark);
  transition: max-height 1s linear, padding 1s linear, border 1s linear;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
  text-decoration: none;
}

.mobile-dropdown.close {
  max-height: 0;
  border-bottom: 0;
  padding: 0;
}

.menu-item {
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  color:var(--color-logo-light);
  font-weight: normal;
  text-decoration: none;
  font-size: 1.5rem;
}

.menu-item:hover {
  text-decoration: underline;
  color:var(--color-text-primary)
}
</style>
