// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const apiKey = process.env.VUE_APP_FB_API_KEY;
const authDomain = process.env.VUE_APP_FB_AUTH_DOMAIN;

const firebaseConfig = {
  apiKey,
  authDomain,
  projectId: 'septacode-com',
  messagingSenderId: '560426253372',
  appId: '1:560426253372:web:5bd057acdefe1198f41739',
  measurementId: 'G-1SP1FC6G89',
};

export default firebaseConfig;
