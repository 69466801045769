import { createApp } from 'vue';
import { initializeApp } from 'firebase/app';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { initializePerformance } from 'firebase/performance';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import {
  getFirestore,
  connectFirestoreEmulator,
  enableMultiTabIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
} from 'firebase/firestore';
import Styleclass from 'primevue/styleclass';
import Ripple from 'primevue/ripple';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import router from '@/router';
import App from '@/App.vue';
import airbrakePlugin from './helpers/airbrakePlugin';
import firebaseConfig from './helpers/firebaseConfig';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const firebaseApp = initializeApp(firebaseConfig);
setAnalyticsCollectionEnabled(getAnalytics(firebaseApp), false);
initializePerformance(firebaseApp, { instrumentationEnabled: true });
const firestoreDb = initializeFirestore(firebaseApp, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});
if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(getAuth(firebaseApp), 'http://localhost:9099');
  connectFirestoreEmulator(getFirestore(firebaseApp), 'localhost', 6001);
  connectFunctionsEmulator(getFunctions(firebaseApp, 'us-central1'), 'localhost', 5001);
  connectFunctionsEmulator(getFunctions(firebaseApp, 'europe-west3'), 'localhost', 5001);
  connectStorageEmulator(getStorage(firebaseApp), 'localhost', 9199);
}

const vueApp = createApp(App);
vueApp.use(airbrakePlugin);
vueApp.use(router);
vueApp.use(PrimeVue, { ripple: true, styleclass: true });
vueApp.use(ToastService);
vueApp.directive('styleclass', Styleclass);
vueApp.directive('ripple', Ripple);

vueApp.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

enableMultiTabIndexedDbPersistence(firestoreDb)
  .catch((error) => {
    vueApp.config.globalProperties.$airbrake.notify({
      error,
      params: { info: this },
    });
  });

router.isReady().then(() => {
  vueApp.mount('#app');
});
