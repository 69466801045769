<script>
/* eslint vuejs-accessibility/anchor-has-content: "off" */

export default {
  name: 'SocialModule',
  data() {
    return {
      social: [{
        label: 'Facebook',
        icon: 'pi pi-facebook',
        url: 'https://www.facebook.com/septacode/?utm_source=septacode.com',
      },
      {
        label: 'Instagram',
        icon: 'pi pi-instagram',
        url: 'https://www.instagram.com/septacode/?utm_source=septacode.com',
      },
      {
        label: 'LinkedIn',
        icon: 'pi pi-linkedin',
        url: 'https://www.linkedin.com/company/septacode/?utm_source=septacode.com',
      }],
    };
  },
};
</script>

<template>
<div class="wrapper">
  <div class="copyright">©SeptaCode 2020-2022</div>
  <div class="logo"></div>
  <div class="social-buttons p-menu">
      <a v-for="(item) in social" v-bind:key="item.label" :href="item.url" target="_blank"
        rel="noopener" class="p-menuitem-link" tabindex="0">
        <span :class="item.icon"></span>
      </a>
  </div>
</div>
</template>

<style scoped lang="scss">
@import "node_modules/include-media/dist/include-media";

.wrapper {
  display: flex;
  flex-direction: column;
  @include media(">28.7em") {
    align-content: flex-start;
  }
  @include media("<=28.7em") {
    align-items: center;
  }
}

.copyright {
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

.logo {
  background: url("../resources/logo-white.svg") no-repeat;
  background-size: contain;
  width: 10em;
  height: 1.2rem;
  margin-bottom: 0.5rem;
}

.social-buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  background: none;
  border: 0;
  color: var(--color-text-primary);
  @include media(">28.7em") {
    justify-content: flex-start;
  }
  @include media("<=28.7em") {
    justify-content: center;
  }
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: none;
}
.p-menu .p-menuitem-link {
  color: var(--color-text-primary);
  padding: 0;
}

.p-menu .p-menuitem-link:not(:first-child) {
  margin-left: 15px;
}

.p-menu .p-menuitem-link {
  color: var(--color-text-primary);
}

.p-menu .p-menuitem-link .pi {
  font-size: 1.5rem;
}
</style>
